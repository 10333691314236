import React from 'react';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
                     <div className="container-fluid Container-Disclaimer pt-5">
                         <div className="row justify-content-center">
                        <div className="d-none d-lg-flex col-8">
                        </div>
                        <div className="col-9 col-lg-9">
                    <div className="roboMed9_11 pb-3">VIENNA LAGER FALL SWEEPSTAKES OFFICIAL RULES
                    </div>
                    <div className="roboMed9_11 pb-5">NO PURCHASE OR PAYMENT OF ANY KIND NECESSARY TO ENTER FOR A CHANCE TO WIN. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING. THE SWEEPSTAKES IS INTENDED FOR VIEWING IN NC & VA ONLY AND WILL BE GOVERNED BY UNITED STATES LAW. VOID WHERE PROHIBITED.</div>
                            <div className="roboMed9_11 pb-5">© 2021 Anheuser-Busch, St. Louis, MO 63118</div>
                    <div className="roboMed15 "> <a href="https://www.dbbviennalager.com/pdf/rules.pdf" target="_blank" role="button" className="linkbtn text-decoration-none">Official Rules</a> |
                                        <a href="https://www.dbbrewingcompany.com/privacy-policy/" target="_blank" role="button" className="linkbtn text-decoration-none">Privacy Policy</a> |
                                        <a href="https://www.dbbrewingcompany.com/terms-conditions/" target="_blank" role="button" className="linkbtn text-decoration-none">Terms Of Use</a></div>
                    </div>
                    </div>
                    </div>
        )
    }
}
export default(Footer);
