import React from 'react';
import './App.css';
import Image from 'react-bootstrap/Image';
import Cookies from 'universal-cookie';
import {withRouter} from "react-router-dom"
import axios from 'axios'
import product from './img/product.png'; // Tell webpack this JS file uses this image
import product2 from './img/product2.jpg'; // Tell webpack this JS file uses this image
import product3 from './img/product3.jpg'; // Tell webpack this JS file uses this image
import logo from './img/logo.png'
import Footer from "./Footer";
import tagline from "./img/tagline.png";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const cookies = new Cookies();

/*function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Blood Orange Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )
}
*/

class Upload extends React.Component {

    constructor(props) {
        super(props);
        if (cookies.get('myAccess') !== 'True') {
            this.props.history.push("/");
        }
        this.btnTapped1 = this.btnTapped1.bind(this);
        this.btnTapped2 = this.btnTapped2.bind(this);
    }

    btnTapped1() {
        this.props.history.push("/submit-pic");
    }

    btnTapped2() {
        var that = this;
        axios.post('https://django.platinumseltzer.com/api/remind4/', {
            email: cookies.get('myEmail'),
        }).then(function (response) {
            window.location = 'https://www.dbbrewingcompany.com/'
        }).catch(function (response) {
            window.location = 'https://www.dbbrewingcompany.com/'
        })
    }

    render() {
        return (
            <div className="App">
                <div className="App2">
                    <div className="container-fluid Main-Container">
                        <div className="row h-100 w-100 justify-content-end">
                            <div className="Main-Right d-none d-md-flex col-3">
                                <Image src={product} alt="Michelob Jump Into Summer" className=" img-fluid Product-Image no-gutters"/>
                            </div>
                            <div className="Main-Center d-flex col-12 col-md-6 ">
                                <div className="container-fluid">
                                    <div className="row Top-Mobile align-items-end justify-content-center">
                                        <div className="d-none col-12 justify-content-center">
                                            <Image src={logo} className="img-fluid Logo-Image" alt="VIENNA LAGER"/>
                                        </div>
                                    </div>
                                    <div className="container-fluid transmiddle">
                                        <div className="contentright">
                                            <div className="drone33 ultrayellow pt-1">Your Entry
                                                has <br/> been received!
                                            </div>
                                            <div className="drone25 ultrablue pt-2">additonal chance to
                                            </div>
                                            <div className="drone71 ultrablue pt-2">WIN $500</div>
                                            <div className="drone30 ultrablue pt-2 pb-2">Share your best <br /> Vienna Lager pic.</div>
                                            <div><Image src={product2} alt="VIENNA LAGER"
                                                        className="img-fluid Product2-Image mb-2"/></div>
                                            <div><Image src={product3} alt="VIENNA LAGER"
                                                        className="img-fluid Product2-Image"/></div>

                                            <div className="w-100 mt-2">
                                                <button type="button" onClick={this.btnTapped1}
                                                        className="btn btn-primary Sucess-Button">Go To entry Form
                                                </button>
                                                <button type="button" onClick={this.btnTapped2}
                                                        className="btn btn-primary Sucess-Button">EMAIL ME A LINK TO THE
                                                    FORM<br/>SO I CAN COMPLETE IT LATER
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex d-md-none pt-2 col-12 justify-content-center">
                                            <Image src={product} alt="VIENNA LAGER" className=" img-fluid Product-Image"/>
                                        </div>
                                        <div className="d-flex d-md-none col-12 justify-content-center">
                                            <Image src={tagline} alt="VIENNA LAGER" className="img-fluid Tag-Image"/>
                                        </div>
                                        <div className="d-flex col-12 justify-content-center">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-Left d-none d-md-flex col-3">
                                <Image src={tagline} alt="VIENNA LAGER" className="img-fluid Tag-Image no-gutters"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Upload);
